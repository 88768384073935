import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Api } from '@citrite/sf-api';
import { configureSolutionsClient } from '@data/clients';
import { withQueryClientProvider } from '@data/helpers/withQueryClientProvider';
import { getDefaultModalProps } from '@overlays/getDefaultOverlayOptions';
import {
	PageComponentProps,
	PiletApi,
	PiletUserContext,
} from '@sharefiledev/sharefile-appshell';
import {
	integrationsEntitlement,
	solutionsEntitlement,
	templatesEntitlement,
} from './entitlements';
import {
	AsyncLazyNavigationButton,
	AsyncLazyPublisherProfileContent,
	AsyncLazyRequestInstallModal,
} from './Extensions';
import { FeatureFlag } from './FeatureFlags';
import { setLogger } from './logger';
import { AsyncCatalogBrowseByTypePage } from './Pages';
import {
	checkRolesAndPreferences,
	RolesAndProvisioningRequirements,
} from './provisioning';
import { basePath, catalogBrowseTypePath, publisherProfilePath } from './routes';
import { packagesDashboardExtensionSlots, rubiconPiletOverlays } from './sdk';

/**
 * Helper to get pilet roles and provisioning requirements,
 * taking into consideration any feature flags for a ggiven account.
 * @returns Roles and provisioning requirements for the Pilet
 */
function getRolesAndProvisioningReqs(): RolesAndProvisioningRequirements {
	const isPLG = getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth);
	return {
		requiredRoles: ['Employee'],
		requiredLaunchDarklyFeatureFlags: [FeatureFlag.EnablePackagesPilet],
		requiredASTs: isPLG ? undefined : ['EnableCatalog'],
	} as RolesAndProvisioningRequirements;
}

/**
 * Checks to see if a user is permitted to access the Pilet
 * @param app Pilet API
 * @returns true if permitted to access Pilet, false if not
 */
function checkIsPermitted(app: PiletApi): boolean {
	// Check if user has sufficient roles and preferences
	if (checkRolesAndPreferences(app, getRolesAndProvisioningReqs())) {
		if (getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth)) {
			// If the user is PLG, next thing to do is to check entitlements.
			const { entitlements }: PiletUserContext = app.sf.piletUserContext.get();
			if (
				!entitlements.getEntitlementStatus(integrationsEntitlement) &&
				!entitlements.getEntitlementStatus(solutionsEntitlement) &&
				!entitlements.getEntitlementStatus(templatesEntitlement)
			) {
				// If entitlements are bad for PLG user, return false (NOT PERMITTED)
				return false;
			}
		}

		// If you get to this point, return true (PERMITTED)
		return true;
	} else {
		// If the user does not have sufficient roles and prefs, return false (NOT PERMITTED)
		return false;
	}
}

/**
 * Sets up the Catalog Pilet, registering the necessary
 * components and extensions needed for the Pilet.
 * @param app Pilet API
 */
export function setup(app: PiletApi) {
	if (!checkIsPermitted(app)) {
		return;
	}

	setLogger(app.sf.getLogger());
	configureApiClients(app);
	registerPages(app);
	registerOverlays(app);
	registerExtensions(app);
}

function registerExtensions(app: PiletApi) {
	app.registerExtension(
		packagesDashboardExtensionSlots.catalogNavigationButton,
		({ params }) => {
			return <AsyncLazyNavigationButton basePath={basePath} {...params} />;
		}
	);
}

function configureApiClients(app: PiletApi) {
	configureSolutionsClient(app);
	app.sf.registerSfApiConfigHandler(Api.configure);
}

function registerOverlays(app: PiletApi) {
	registerRequestInstallModal(app);
}

function registerRequestInstallModal(app: PiletApi) {
	app.registerModal(
		rubiconPiletOverlays.requestInstallModal,
		withQueryClientProvider(props => (
			<AsyncLazyRequestInstallModal {...props} piletApi={app} />
		)),
		getDefaultModalProps({
			className: 'modal-request-solution-install',
		})
	);
}

function registerPages(app: PiletApi) {
	registerBasePathPage(app);
	registerPublisherProfilePage(app);
	registerCatalogBrowsePage(app); // Needs to happen last
}

// Register `/catalog` route
function registerBasePathPage(app: PiletApi) {
	app.registerPage(basePath, () => <AsyncCatalogBrowseByTypePage app={app} />, {
		pageType: 'solutionsCatalog',
	});
}

// Register `/catalog/publisher?namespace={namespace}` route
function registerPublisherProfilePage(app: PiletApi) {
	const PublisherProfileShell = withQueryClientProvider((_props: PageComponentProps) => {
		return <AsyncLazyPublisherProfileContent basePath={basePath} piletApi={app} />;
	});

	app.registerPage(publisherProfilePath, PublisherProfileShell, {
		pageType: 'solutionsCatalog',
	});
}

// Register `/catalog/:type` route
function registerCatalogBrowsePage(app: PiletApi) {
	app.registerPage(
		catalogBrowseTypePath,
		() => <AsyncCatalogBrowseByTypePage app={app} />,
		{
			pageType: 'solutionsCatalog',
		}
	);
}
